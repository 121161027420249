import * as React from "react"
import { faPlay } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface Props {
  resources?: Array<Resource>
}

interface Resource {
  title: string
  subtitle?: string
  description: string
  image: string
  url: string
  video?: boolean
}

const Resource = ({ resource }: { resource: Resource }) => {
  return (
    <article className="column resource is-4 p-5">
      <div className="image">
        <a href={resource.url}>
          <img src={`/img/extra/${resource.image}`} alt={resource.title} />
          {resource?.video && (
            <span className="ico">
              <FontAwesomeIcon className="icon play-button" icon={faPlay} />
            </span>
          )}
        </a>
      </div>
      <h1 className="is-size-4 has-text-weight-bold py-4">
        <a className="link" href={resource.url}>
          {resource.title}
          {resource.subtitle && `: ${resource.subtitle}`}
        </a>
      </h1>
      <div>{resource.description}</div>
    </article>
  )
}

const Resources = ({ resources }: Props) => {
  return (
    <div className="py-6 my-6">
      <h2 className="is-size-2 is-size-3-mobile has-text-weight-bold py-5">
        More resources
      </h2>
      <div className="columns">
        {resources?.map((resource: Resource, index: number) => (
          <Resource key={`resource-${index}`} resource={resource} />
        ))}
      </div>
    </div>
  )
}

export default Resources
