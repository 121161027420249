import { graphql, Link } from "gatsby"
import * as React from "react"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import { HTMLContent } from "../components/common/Content"
import Layout from "../components/layout/Layout"
import Resources from "../components/projects/Resources"

// props
interface Props {
  data: {
    page: {
      html: any
      frontmatter: {
        title: string
        description?: string
        keywords?: Array<string>
        resources: Array<{
          title: string
          description: string
          url: string
          image: string
        }>
        features: Array<{
          title: string
          description: string
          paragraph?: string
          support?: string
          languages?: string
        }>
      }
    }
  }
}

const Resource = ({ resource, resourceSize, resourceClass, playIcon }: any) => (
  <article className={`column is-${resourceSize || "4"} p-5 ${resourceClass}`}>
    <div className="image">
      <a href={resource.url}>
        <img src={`/img/extra/${resource.image}`} alt={resource.title} />
      </a>
    </div>
    {playIcon && (
      <div className="play">
        <div className="relative">
          <div className="triangle" />
        </div>
      </div>
    )}
    <div className="resource-title mt-3">{resource.title}</div>
    <div className="description">
      <a className="link link-darken" href={resource.url}>
        {resource.description}
      </a>
    </div>
  </article>
)

// markup
const WebApplicationsTemplate = (props: Props) => {
  const resources = props.data.page.frontmatter.resources
  const features = props.data.page.frontmatter.features
  return (
    <Layout
      seo={{
        title: props.data.page.frontmatter.title,
        description: props.data.page.frontmatter.description,
        keywords: props.data.page.frontmatter.keywords,
      }}
      className={`webapplications`}
    >
      <div className="extra-0">
        <img src={`/img/extra/microservices-4.svg`} alt="" />
      </div>
      <div className="container">
        <div className="hero-webapplications content py-6">
          <HTMLContent
            content={props.data.page.html}
            className={"markdown links-animate"}
          ></HTMLContent>
          <img className="image" src={`/img/icons/web-apps.svg`} alt="" />
        </div>

        <div className="markdown content">
          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[0].title}
              </h1>
            </div>
            <div className="column is-8">
              <HTMLContent
                className="mb-4"
                content={features[0].description}
              ></HTMLContent>
              <div className="columns">
                <div className="column links-animate is-6 pr-6">
                  <div className="mt-3">{features[0].paragraph}</div>
                  <div className="mt-3">
                    {features[0].support}: {features[0].languages}
                  </div>
                  <div className="pt-5">
                    <Link to={`/guides/gs/rest-service`}>
                      Try our REST Service guide
                    </Link>
                  </div>
                </div>
                <Resource
                  playIcon={true}
                  resource={resources[resources.length - 1]}
                  resourceSize="6"
                  resourceClass="video"
                />
              </div>
            </div>
          </article>
          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[1].title}
              </h1>
            </div>
            <div className="column links-animate is-8">
              <HTMLContent content={features[1].description}></HTMLContent>
              <div className="mt-4">
                <HTMLContent content={features[1].paragraph}></HTMLContent>
              </div>
              <div className="pt-3">
                <Link to={`/guides/gs/securing-web/`}>
                  Try our Securing Web Applications Guide
                </Link>
              </div>
            </div>
          </article>

          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[2].title}
              </h1>
            </div>
            <div className="column links-animate is-8">
              <div className="columns is-mobile">
                <div className="column is-2">
                  <div className="img pr-5 has-text-right-desktop">
                    <img
                      className="img-light"
                      src={`/img/extra/webapplications-2.svg`}
                      width={100}
                      alt=""
                    />
                    <img
                      className="img-dark"
                      src={`/img/extra/webapplications-2-dark.svg`}
                      width={100}
                      alt=""
                    />
                  </div>
                </div>
                <div className="column is-10 pl-6">
                  <HTMLContent content={features[2].description}></HTMLContent>
                  <div className="mt-5 mb-5">
                    <a className="blue" href="/guides/gs/accessing-data-jpa/">
                      Get Started with JPA
                    </a>{" "}
                    or{" "}
                    <a
                      className="blue"
                      href="/guides/gs/accessing-data-mongodb/"
                    >
                      Get Started with MongoDB
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>

        <div className="has-background-light mt-6 p-6 has-text-centered">
          <div className="py-5">
            <h2 className="is-size-2 is-size-3-mobile has-text-weight-medium pb-6">
              Ready to get started?
            </h2>
            <Link className="button is-spring" to={`/quickstart`}>
              TRY THIS TUTORIAL
            </Link>
          </div>
        </div>

        <Resources resources={[resources[0], resources[1], resources[2]]} />
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default WebApplicationsTemplate

// graphQL queries
export const pageQuery = graphql`
  query WebApplicationsTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        keywords
        defaultClass
        resources {
          title
          description
          image
          url
        }
        features {
          title
          description
          paragraph
          support
          languages
        }
      }
    }
  }
`
