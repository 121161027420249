import { Link } from "gatsby"
import * as React from "react"

export const DefaultBottom = () => (
  <div className="default-banner has-background-light py-6">
    <div className="extra is-hidden-mobile is-hidden-tablet-only">
      <div className="extra-1"></div>
      <div className="extra-2">
        <img src="/img/extra/footer.svg" alt="" />
      </div>
    </div>
    <div className="content py-6 has-text-left">
      <div className="container">
        <div className="columns is-variable is-8">
          <article className="column">
            <h1 className="is-size-4 has-text-weight-bold mb-2">Get ahead</h1>
            <p className="mb-1">
              VMware offers training and certification to turbo-charge your
              progress.
            </p>
            <Link className="is-link link-animate" to={"/training"}>
              Learn more
            </Link>
          </article>
          <article className="column">
            <h1 className="is-size-4 has-text-weight-bold mb-2">Get support</h1>
            <p className="mb-1">
              Spring Runtime offers support and binaries for OpenJDK™, Spring,
              and Apache Tomcat® in one simple subscription.
            </p>
            <Link className="is-link link-animate" to={"/support"}>
              Learn more
            </Link>
          </article>
          <article className="column">
            <h1 className="is-size-4 has-text-weight-bold mb-2">
              Upcoming events
            </h1>
            <p className="mb-1">
              Check out all the upcoming events in the Spring community.
            </p>
            <Link className="is-link link-animate" to={"/events"}>
              View all
            </Link>
          </article>
        </div>
      </div>
    </div>
  </div>
)
